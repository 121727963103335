













import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { Component, mixins, Prop } from 'nuxt-property-decorator'
import { SectionCore } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'

@Component
class SectionCustom extends mixins(SectionCore) {
  @Prop() readonly isLayoutStyles: boolean = false
}

export default defineComponent({
  name: 'SectionCustom',
  components: { SectionBasic },
  props: SectionCustom.options.props,
  setup({ sectionData }) {
    const html = ref<string>('')

    const loadScript = (scripts: Array<string>) => {
      return new Promise((resolve) => {
        scripts.forEach((script) => {
          const scriptJs = document
            .createRange()
            .createContextualFragment(script)
          document.getElementById('custom-block').append(scriptJs)
        })

        resolve()
      })
    }

    onMounted(() => {
      const customCode = sectionData.customCode

      const regExpScripts = new RegExp(/<script.*?<\/script>/gis)

      let result = null
      const scripts = []

      while ((result = regExpScripts.exec(customCode))) {
        scripts.push(result[0])
      }

      html.value = customCode.replace(regExpScripts, '')

      loadScript(scripts)
    })

    return {
      html,
    }
  },
})
